<template>
  <div>
    <Breadcrumbs title="Subjects" main="Master Data"/>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
<!--            <div class="card-header text-lg-right">-->
<!--              <b-button class="btn-square" variant="success" @click="createRow">New Town</b-button>-->
<!--            </div>-->
            <div class="card-body">


              <div class="row">
                <div class="m-t-6 col-12 col-md-6">
                  <b-input-group>
                    <b-form-input v-model="filter" placeholder="Type to Search" class="border btn-square" autofocus></b-form-input>
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''" class="btn btn-square border" variant="primary">Clear</b-button>
                    </b-input-group-append>
                    <b-input-group-append>
                      <b-button v-show="checkUserPermissions(user,['create_subjects'])" @click="showCreateModal" class="btn btn-square border" variant="success">New Subject</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </div>
                <div class="m-t-10 col-12 col-md-6 text-md-right">
                  <b-form-group label-cols="9" label="Per Page">
                    <b-form-select class="btn-square border text-center" v-model="perPage" :options="pageOptions"></b-form-select>
                  </b-form-group>
                </div>
              </div>



              <div class="row" v-if="false">
                <div class="col-md-9 delete-datatablex">
                  <b-input-group class="datatable-btnx m-t-10">
                    <b-form-input v-model="filter" placeholder="Type to Search" class="border btn-square" autofocus></b-form-input>
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="clearSearch" class="btn-square btn-dark">Clear</b-button>
                      <b-button :disabled="!filter" @click="clearSearch" class="btn-square btn-success">Add Record</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </div>
                <div class="col-12 col-md-3 text-right m-t-10">
                  <b-form-group label-cols="9" label="Per Page" class="datatable-selectx">
                    <b-form-select class="btn-square border" v-model="perPage" :options="pageOptions"></b-form-select>
                  </b-form-group>
                </div>
              </div>

              <div class="table-responsive datatable-vue">
                <b-table
                    ref="table"
                    show-empty
                    selected-variant="success"
                    :items="tableRecords"
                    :fields="tableFields"
                    :filter="filter"
                    :current-page="1"
                    :per-page="perPage"
                    @filtered="onFiltered"
                    @row-selected="rowSelected"
                >
                  <template #cell(streams)="row">{{ row.item.streams.map((stream) => stream.name).join(', ') }}</template>
                  <template #cell(status)="row">{{ statusOptions[row.item.status].text }}</template>
                  <template #cell(action)="row" class="text-right">
                    <b-button v-show="checkUserPermissions(user,['update_subjects'])" class="btn-square mr-1 ml-1" variant="success" @click="showEditModal(row.index, row.item, $event.target)">Edit</b-button>
                    <b-button v-show="checkUserPermissions(user,['delete_subjects'])" class="btn-square" variant="danger" @click="showDeleteModal(row.index, row.item, $event.target)">Remove</b-button>
                  </template>
                </b-table>
              </div>


              <b-row >
                <b-col cols="4">
                  Records: <strong>{{ totalRows }}</strong>
                </b-col>
                <b-col cols="8" class="my-1" v-if="totalRows">
                  <b-pagination
                      align="right"
                      class="my-0"
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      pills
                  ></b-pagination>
                </b-col>
              </b-row>





              <!-- Begin Delete Modal -->
              <b-modal id="delete_modal" title="Delete" cancel-variant="default" ok-variant="danger" ok-title="Confirm" @hide="clearFormModals" @ok="submitDeleteModal">
                <h6 class="m-b-10">Please confirm removal of following record:</h6>
                <strong v-text="selected.name"></strong>
              </b-modal>
              <!-- End Delete Modal -->

              <!-- Begin Create Modal -->
              <b-modal id="form_modal" :title="form_modal_title" cancel-variant="default" ok-variant="primary" :ok-title="form_modal_ok_title" @hide="clearFormModals" @ok="submitFormModal">

                <b-form-group v-if="formModal.id" label="Id">
                  <b-input v-if="form_modal_id === 'form_modal_create'" v-model="formModal.id"></b-input>
                  <b-input v-else v-model="formModal.id" readonly></b-input>
                </b-form-group>

                <b-form-group label="Name">
                  <b-input v-model="formModal.name"></b-input>
                </b-form-group>

                <div class="animate-chk">
                  <b-form-group label="Streams *">
                    <div class="row">
                      <div class="checkbox-animated col-6" v-for="(stream, index) in streams" :key="index">
                        <label class="d-block">
                          <input class="checkbox_animated" name="formModal.streams" type="checkbox"
                                 v-model="formModal.streams"
                                 :checked="formModal.streams.includes(stream.id)"
                                 :value="stream.id"
                          > {{stream.text}}
                        </label>
                      </div>
                    </div>
                  </b-form-group>
                </div>

              </b-modal>
              <!-- End Create Modal -->

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {checkUserPermissions} from '@/plugins/functions'
import { mapGetters, mapState } from 'vuex'
import CRUD_API from '@/services/crud'
export default {
  name: "subjects",
  data() {
    return {
      filter: '',
      totalRows: 0,
      perPage: 5,
      currentPage: 1,
      pageOptions: [5,10,25,50,100],
      tableRecords: [],
      selected: [],

      api_base: '/api/backend/pages/subjects',
      tableFields: [
        // { key: 'id', label: 'ID', sortable: false },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'streams', label: 'Streams', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'action', label: 'Action', sortable: false, class: 'text-right text-capitalize' }
      ],
      statusOptions: [
        { id: 0, text: "Active" },
        { id: 1, text: "Inactive" },
      ],


      // Form Modal
      form_modal_id: '',
      form_modal_title: '',
      form_modal_ok_variant: 'primary',
      form_modal_ok_title: '',
      formModal: {
        index: null,
        id: null,
        name: null,
        status: null,
        streams: []
      },
      streams: [],
    }
  },
  watch: {
    currentPage() {
      this.fetchData()
    },
    perPage() {
      this.currentPage = 1
      this.fetchData()
    },
    filter() {
      this.currentPage = 1
      this.fetchData()
    }
  },
  mounted() {
    this.fetchStreams()
    this.fetchData()
  },
  computed: mapState({ user: state => JSON.parse(state.auth.user) }),
  methods: {
    checkUserPermissions,
    onFiltered(filteredItems) {
      this.currentPage = 1
      this.items.count = filteredItems.length
    },
    rowSelected(item) {
      this.selected = item
    },
    clearSearch() {
      this.filter = ''
      this.currentPage = 1
      this.fetchData()
    },
    handlePageChange(value) {
      //alert(value)
      this.perPage = value
      this.fetchData()
    },
    handlePageSizeChange(value) {
      this.currentPage = value
      this.fetchData()
    },
    async fetchData() {
      await CRUD_API.index( this.api_base, {
        params: {
          per_page: this.perPage,
          page: this.currentPage,
          filter: this.filter,
          with_relations: ['streams']
        }
      }).then(function(resp) {
        this.tableRecords = []
        //this.currentPage = resp.data.data.current_page
        //this.perPage = resp.data.data.per_page
        this.totalRows = resp.data.data.total
        this.tableRecords = resp.data.data.data
      }.bind(this)).catch((err) => {
        console.log(err)
        this.$toasted.error("Error occurred while retrieving data")
      }).finally(() => {
        this.$refs.table.refresh()
      })
    },

    clearFormModals() {
      this.formModal.index = null
      this.formModal.id = null
      this.formModal.name = null
      this.formModal.status = 0
      this.formModal.streams = []
    },
    submitFormModal(){
      let params = {
        name: this.formModal.name,
        status: this.formModal.status,
        streams: this.formModal.streams || []
      }
      if (this.formModal.id) {
        CRUD_API.update(this.api_base, this.formModal.id, params)
            .then((resp) => {
              console.log(resp)
              this.$toasted.success("Subject updated successfully")
            })
            .catch((err) => {
              console.log(err)
              this.$toasted.error("Error occurred while updating subject")
            })
            .finally(() => {
              this.fetchData()
            })
      } else {
        CRUD_API.create(this.api_base, params)
            .then((resp) => {
              console.log(resp)
              this.$toasted.success("Subject added successfully")
            })
            .catch((err) => {
              console.log(err)
              this.$toasted.error("Error occurred while saving subject")
            })
            .finally(() => {
              this.fetchData()
            })
      }
    },
    showCreateModal(button) {
      this.clearFormModals()
      this.selected = { id: null, name: null, status: null }
      this.form_modal_title = "New Stream"
      this.form_modal_ok_title = 'Submit'
      this.$root.$emit('bv::show::modal', 'form_modal', button)
    },
    showEditModal(index, item, button) {
      this.formModal.index = index
      this.formModal.id = item.id
      this.formModal.name = item.name
      this.formModal.status = item.status
      this.formModal.streams = item.streams.map((stream) => stream.id)
      this.form_modal_title = "Edit Subject"
      this.form_modal_ok_title = 'Update'
      this.$root.$emit('bv::show::modal', 'form_modal', button)
    },

    showDeleteModal(index, item, button){},
    submitDeleteModal() {},

    async fetchStreams() {
      await CRUD_API.index( '/api/backend/select/streams').then(function(resp) {
        this.streams = resp.data.data
      }.bind(this)).catch((err) => {
        console.log(err)
        this.streams = []
        this.$toasted.error("Error occurred while retrieving streams")
      })
    }

  }
}
</script>

<style scoped>

</style>
